import Footer from '@/components/organisms/Footer/Footer';
import { mockFooterProps } from '@/components/organisms/Footer/Footer.mocks';
import Navbar from '@/components/organisms/Navbar/Navbar';
import ToastHandler from '@/components/organisms/ToastHandler/ToastHandler';

/**
 * IMinimalLayout
 *
 * @interface
 */
export interface IMinimalLayout extends React.ComponentPropsWithoutRef<'div'> {
  /** The children of the component */
  children: React.ReactNode;
}

/**
 * Minimal Layout Used to display the minimal layout of the application.
 *
 * @param {IMinimalLayout} props - The props for the MinimalLayout component.
 * @returns {React.FC<IMinimalLayout>} MinimalLayout Component
 */
const MinimalLayout: React.FC<IMinimalLayout> = ({
  children,
}: IMinimalLayout) => {
  return (
    <>
      <Navbar minimal={true} />
      <main data-testid="minimalLayout">
        {children}
        <ToastHandler />
      </main>
      <Footer {...mockFooterProps.base} />
    </>
  );
};

export default MinimalLayout;
